import { useForm } from 'react-hook-form'
import {
    FormLabel,
    FormControl,
    Input,
    Button,
    Text,
    Center,
    Flex,
    Spacer,
    FormErrorMessage,
    Checkbox
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import {authService} from "../service/staticInstances";

export default function RegisterCard() {
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm()

    function onSubmit(values: any) {
        return new Promise((resolve) => { 
            setTimeout(() => {
                const result = authService.register(values['email'], values['password'], values['name']);
                resolve(values)
            })
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Center >
                <Flex direction={"column"} boxShadow="xl" bgColor="white" mt={10} p={8} rounded='lg'>
                    <FormControl isInvalid={errors.name != undefined}>
                        <Flex direction={"column"} gap={2}>
                            <Text fontSize="lg" fontWeight="bold">Registrate</Text>
                            <FormLabel htmlFor='name' mt={3}>Nombre y Apellido</FormLabel>
                            <Input
                                id='name'
                                placeholder='Ingresá tu nombre completo'
                                {...register('name', {
                                    required: 'Campo obligatorio',
                                    minLength: { value: 4, message: 'Ingresá un nombre válido' },
                                })}
                            />
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <Input
                                id='email'
                                placeholder='Ingresá tu dirección de email'
                                {...register('email', {
                                    required: 'Campo obligatorio',
                                    minLength: { value: 4, message: 'Ingresá un email válido' },
                                })}
                            />
                            <FormLabel htmlFor='password'>Contraseña</FormLabel>
                            <Input
                                id='password'
                                placeholder='Ingresá tu contraseña'
                                {...register('password', {
                                    required: 'Campo obligatorio',
                                })}
                            />
                            <FormErrorMessage>
                                {errors.name && errors.name.message}
                            </FormErrorMessage>
                        </Flex>
                    </FormControl>
                    <FormControl isInvalid={errors.terms != undefined}>
                        <Flex direction={"row"} mt={4}>
                            <Checkbox
                                {...register('terms', {
                                    required: 'Debes aceptar los términos y condiciones',
                                })}
                            >
                                Acepto los
                            </Checkbox>
                            <Link to="/terms">
                                <Text ml="1" fontWeight="semibold" color={"#3757ff"} fontSize={"small"}>Términos y condiciones</Text>
                            </Link>
                        </Flex>
                        <FormErrorMessage>
                            {errors.terms && errors.terms.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Button mt={4} colorScheme='red' isLoading={isSubmitting} type='submit'>
                       Registrate
                    </Button>
                    <Center mt={4}>
                        <Text fontSize={"small"}>Ya tenés una cuenta?</Text>
                        <Link to="/login">
                            <Text ml="1" fontWeight="semibold" color={"#3757ff"} fontSize={"small"}>Iniciá Sesión</Text>
                        </Link>
                    </Center>
                </Flex>
            </Center>
        </form>
    )
}
