import HomeBanner from "../components/HomeBanner";
import InvestmentOpportunities from "../components/InvestmentOpportunities";
import RoiTable from "../components/RoiTable";
import RealEstateOpportunities from "../components/RealEstateOpportunities";
import {Divider, Flex} from "@chakra-ui/react";
import VehicleOpportunities from "../components/VehicleOpportunities";
import AboutBancaStokker from "../components/AboutBancaStokker";

const Home = () => {
    return (
        <Flex direction="column" pb="10em">
            <HomeBanner/>
            <InvestmentOpportunities/>
            <RoiTable/>
            <RealEstateOpportunities/>
            <VehicleOpportunities/>
            <Divider/>
            <AboutBancaStokker/>
        </Flex>
    );
}

export default Home;