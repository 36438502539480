import { useEffect, useState } from "react";
import {
    chakra,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    FormControl,
    FormLabel,
    Select,
    WrapItem,
    Wrap,
    Flex
} from '@chakra-ui/react';
import { Text, Tabs, TabList, TabPanels, Tab, TabPanel, Center, Input, NumberInput, NumberInputField } from '@chakra-ui/react'
import { Search2Icon } from "@chakra-ui/icons";
import { AdminServise } from "../service/AdminService";
import RedAndBlackTitle from "../components/RedAndBlackTitle";

const Bt = chakra('button', {
    baseStyle: {
        "bg": 'rgb(26,32,44)',
        "marginTop": 2,
        "p": 2,
        "shadow": "lg",
        "rounded": "lg",
        "_hover": { bg: 'rgb(229,62,62)' },
    }
});

const adminOptions = [
    { "aop": "Agregar transaccion" },
    { "aop": "Eliminar transaccion" },
    { "aop": "Aprobar transaccion" },
    { "aop": "Aprobar retiro" },
];

const planOptions = [
    { "plan": "Plan 12% a 12" },
    { "plan": "Plan 15% a 24" },
    { "plan": "Plan 15% a 36" }
];

const breakpoint = { sm: "100%", md: "100%", lg: "50%", xl: "30%" };

export default function Manage() {

    const adminService = new AdminServise;

    const [selectedEmail, setEmail] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const [productName, setProduct] = useState('');
    const [baseAmount, setBaseAmount] = useState(0);
    const [acumAmount, setAcumAmount] = useState(0);
    const [date, setDate] = useState('');
    const [price, setPrice] = useState(0);

    useEffect(() => {
        adminService.getUsers().then((response) => {
            setEmailList(response.data)
        })
    }, [])


    const handleChange = e => {
        setEmail(e.target.value);
    };

    const find = async () => {
        await adminService.requestBalance(selectedEmail).then((response) => {
            setTransactions(response.data);
        })
        console.log(transactions);
    }

    const handleProductChange = e => {
        setProduct(e.target.value);
    }
    const handleBaseAmountChange = e => {
        setBaseAmount(e.target.value);
    }
    const handleAcumAmountChange = e => {
        setAcumAmount(e.target.value);
    }
    const handelDateChange = e => {
        setDate(e.target.value);
    }
    const handlePriceChange = e => {
        setPrice(e.target.value);
    }

    const aprobeWithdraw = async () => {
        adminService.confirmWithdraw(selectedEmail, price, productName);
        find();
    }

    const aprobeTransaction = async () => {
        adminService.changeStatus(selectedEmail, productName, price);
        find();
    }

    const addTransaction = async () => {
        await adminService.addTransaction(selectedEmail, productName, baseAmount, acumAmount);
        find();
    }

    const delteTransaction = async () => {
        await adminService.deleteTransaction(selectedEmail, productName, date, baseAmount, acumAmount);
        find();
    }

    return (
        <div>
            <Box m="50px" p={5} shadow="lg" borderRadius="lg">
                <RedAndBlackTitle redTitle="Tabla de transacciones" blackTitle="" />
                <TableContainer shadow="inner" p={3} borderRadius="lg">
                    <FormControl isRequired p={5}>
                        <FormLabel>Email del inversor</FormLabel>
                        <Wrap>
                            <Select placeholder='Seleccione' width={["38vw", "20vw"]} onClick={handleChange}>
                                {
                                    emailList.map((emailOptions: any) => {
                                        return (<option key={emailOptions.email}>{emailOptions.email}</option>)
                                    }
                                    )
                                }
                            </Select>
                            <Bt width={["38vw", "10vw"]} onClick={find}><Search2Icon color="white" width="vw" /></Bt>
                        </Wrap>
                    </FormControl>
                    <Table variant='striped' colorScheme='red'>
                        <Thead>
                            <Tr>
                                <Th>Nombre del producto</Th>
                                <Th>Fecha</Th>
                                <Th>Estado</Th>
                                <Th isNumeric>Monto base</Th>
                                <Th isNumeric>Interés acumulado</Th>
                                <Th isNumeric>Min. Term.</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                transactions.map((column: any) => {
                                    return (
                                        <Tr>
                                            <Td key={column.productname}>{column.productname}</Td>
                                            <Td key={column.creationdate}>{column.creationdate}</Td>
                                            <Td key={column.status}>{column.status === 0 ? 'Pendiente' : 'Confirmada'}</Td>
                                            <Td key={column.base_amount} isNumeric><Text>{column.base_amount} U$D</Text></Td>
                                            <Td key={column.accumulated_interest} isNumeric><Text>{column.accumulated_interest} U$D</Text></Td>
                                            <Td key={column.minimumterm} isNumeric>{column.minimumterm}</Td>
                                        </Tr>
                                    )
                                }
                                )
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <Box m="50px" p={5} shadow="lg" borderRadius="lg" >
                <Tabs variant='soft-rounded' colorScheme='red' width={["100%", "100%", "100%", "100%"]}>
                    <Wrap align="center" justify="center">
                        <WrapItem>
                            <Center>
                                <TabList flexDirection={["column", "column", "row", "row"]}>
                                    {adminOptions.map((option) => (<Tab key={option.aop}>{option.aop}</Tab>))}
                                </TabList>
                            </Center>
                        </WrapItem>
                    </Wrap>
                    <TabPanels>

                        <TabPanel >
                            <FormControl isRequired shadow="inner" p={5} borderRadius="lg" width={["55vw", "100%"]}>
                                <FormLabel>Email del inversor</FormLabel>
                                <Select value={selectedEmail} width={breakpoint} placeholder='Seleccione' onClick={handleChange}>
                                    {
                                        emailList.map((emailOptions: any) => {
                                            return (<option key={emailOptions.email}>{emailOptions.email}</option>)
                                        }
                                        )
                                    }
                                </Select>

                                <FormLabel marginTop="5px">Nombre del producto</FormLabel>
                                <Select value={productName} placeholder='Seleccione' width={breakpoint} onChange={handleProductChange}>
                                    {planOptions.map((option) => (<option key={option.plan} value={option.plan}>{option.plan}</option>))}
                                </Select>

                                <FormLabel marginTop="5px">Monto Base</FormLabel>
                                <NumberInput value={baseAmount} width={breakpoint} min={0}><NumberInputField placeholder="0000.00 USD" onChange={handleBaseAmountChange} /></NumberInput>

                                <FormLabel marginTop="5px">Interés Acumulado</FormLabel>
                                <NumberInput value={acumAmount} width={breakpoint} min={0}><NumberInputField placeholder="0000.00 %" onChange={handleAcumAmountChange} /></NumberInput>
                                <Bt width={["100%", "100%", "100%", "20%"]} onClick={addTransaction}><Text color="white" width="100hv">Enviar</Text></Bt>

                            </FormControl>
                        </TabPanel>

                        <TabPanel >
                            <FormControl isRequired shadow="inner" p={5} borderRadius="lg" width={["55vw", "100%"]}>
                                <FormLabel>Email del inversor</FormLabel>
                                <Select value={selectedEmail} placeholder='Seleccione'  width={breakpoint} onClick={handleChange}>
                                    {
                                        emailList.map((emailOptions: any) => {
                                            return (<option key={emailOptions.email}>{emailOptions.email}</option>)
                                        }
                                        )
                                    }
                                </Select>

                                <FormLabel marginTop="5px">Nombre del producto</FormLabel>
                                <Select placeholder='Seleccione'  width={breakpoint} onChange={handleProductChange}>
                                    {planOptions.map((option) => (<option key={option.plan} value={option.plan}>{option.plan}</option>))}
                                </Select>

                                <FormLabel>Fecha</FormLabel>
                                <Input borderRadius="lg" type="Date" placeholder='yyyy/mm/dd'  width={breakpoint} onChange={handelDateChange} />

                                <FormLabel marginTop="5px">Monto Base</FormLabel>
                                <NumberInput value={baseAmount} min={0}  width={breakpoint}><NumberInputField placeholder="0000 USD" onChange={handleBaseAmountChange} /></NumberInput>


                                <FormLabel marginTop="5px">Interés Acumulado</FormLabel>
                                <NumberInput value={acumAmount}  width={breakpoint} min={0}><NumberInputField placeholder="0000.00 %" onChange={handleAcumAmountChange} /></NumberInput>

                                <Bt width={["100%", "100%", "100%", "20%"]} onClick={delteTransaction}><Text color="white">Enviar</Text></Bt>
                            </FormControl>
                        </TabPanel>

                        <TabPanel>
                            <FormControl isRequired shadow="inner" p={5} borderRadius="lg" width={["55vw", "100%"]}>
                                <FormLabel>Email del inversor</FormLabel>
                                <Select value={selectedEmail} placeholder='Seleccione'  width={breakpoint} onClick={handleChange}>
                                    {
                                        emailList.map((emailOptions: any) => {
                                            return (<option key={emailOptions.email}>{emailOptions.email}</option>)
                                        }
                                        )
                                    }
                                </Select>
                                <FormLabel marginTop="5px">Nombre del producto</FormLabel>
                                <Select value={productName} placeholder='Seleccione'  width={breakpoint} onChange={handleProductChange}>
                                    {planOptions.map((option) => (<option key={option.plan} value={option.plan}>{option.plan}</option>))}
                                </Select>
                                <FormLabel marginTop="5px">Precio</FormLabel>
                                <NumberInput value={price}  width={breakpoint} min={0}><NumberInputField placeholder="0000 USD" onChange={handlePriceChange} /></NumberInput>
                                <Bt width={["100%", "100%", "100%", "20%"]} onClick={aprobeTransaction}><Text color="white">Enviar</Text></Bt>
                            </FormControl>
                        </TabPanel>

                        <TabPanel >
                            <FormControl isRequired shadow="inner" p={5} borderRadius="lg" width={["55vw", "100%"]}>
                                <FormLabel>Email del inversor</FormLabel>
                                <Select value={selectedEmail} placeholder='Seleccione' width={breakpoint} onClick={handleChange}>
                                    {
                                        emailList.map((emailOptions: any) => {
                                            return (<option key={emailOptions.email}>{emailOptions.email}</option>)
                                        }
                                        )
                                    }
                                </Select>
                                <FormLabel marginTop="5px">Nombre del producto</FormLabel>
                                <Select value={productName} placeholder='Seleccione'  width={breakpoint} onChange={handleProductChange}>
                                    {planOptions.map((option) => (<option key={option.plan} value={option.plan}>{option.plan}</option>))}
                                </Select>
                                <FormLabel marginTop="5px">Precio</FormLabel>
                                <NumberInput value={price} width={breakpoint} min={0}><NumberInputField placeholder="0000 USD" onChange={handlePriceChange} /></NumberInput>
                                <Bt width={["100%", "100%", "100%", "20%"]} onClick={aprobeWithdraw}><Text color="white">Enviar</Text></Bt>
                            </FormControl>
                        </TabPanel>

                    </TabPanels>
                </Tabs>
            </Box>
        </div>
    )
}
