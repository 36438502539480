import React from 'react';
import QuestionAndAnswer from "../components/QuestionAndAnswer";
import {Flex, Text} from "@chakra-ui/react";
import theme from "../chakraTheme";

const FAQs = () => {
    return (
        <Flex direction="column">
            <Flex justify="center" bgColor={theme.colors.secondary} padding={20}>
                <Text textColor={"white"} fontSize="3xl" fontWeight="bold">Preguntas &nbsp; </Text>
                <Text textColor={theme.colors.accent} fontSize="3xl" fontWeight="bold">Frecuentes</Text>
            </Flex>
            <QuestionAndAnswer  question="¿Qué es el Crowdfunding?
" answer="El crowdfunding* es una red de financiación colectiva que, a través del aporte económico de diferentes inversores, consigue financiar determinados proyectos a cambio de participar de la rentabilidad del negocio.
Es también una oportunidad igualitaria e inclusiva para aquellas personas que hasta hoy quedaban fuera de poder participar en este tipo de inversiones.
(*) El término viene del vocablo inglés 'crowd', que se refiere a multitud, y 'funding', que significa financiación.
"/>
            <QuestionAndAnswer  question="¿Qué es el Crowdfunding Inmobiliario?
" answer="El crowdfunding aplicado a los inmuebles es, tal cual lo mencionado, una red de financiación colectiva que consigue financiar proyectos del rubro. Permite, de esta manera, y considerando tickets mínimos reducidos, dar lugar a medianos y pequeños inversores.
"/>
            <QuestionAndAnswer  question="¿Cuál es el formato legal de BancaStokker?
" answer="El formato legal adoptado para recaudar los fondos de los inversores es el de Sociedad Anonima independiente de cada proyecto. Esta figura legal protege a cada inversor y su inversión haciendolo dueño de una cuotaparte de la S.A. de acuerdo a la inversiòn y el valor de la S.A .
" />
            <QuestionAndAnswer question="¿Mis datos personales están protegidos?
" answer="Los datos personales están totalmente protegidos y a resguardo, solo para el uso exclusivo de nuestro sistema.
"/>
            <QuestionAndAnswer question="¿Quién puede participar en los proyectos de BancaStokker?
" answer="Cualquier persona física (mayor 18 años).
"/>
            <QuestionAndAnswer question="¿Cuál es el monto mínimo de inversión?
" answer="Pueden hacerse inversiones desde USD 5000.-"/>
            <QuestionAndAnswer question="¿Hay límite máximo de inversión?" answer="No. El límite de inversión lo pone la cobertura total de los proyectos de inversión.
"/>
            <QuestionAndAnswer question="¿Tienen alguna comisión que impacte sobre la rentabilidad?
" answer="No. La renta estimada es neta de comisiones
"/>
            <QuestionAndAnswer question="¿Como elegir un proyecto?
" answer="Para hacerlo adecuadamente sugerimos poner en consideración todos los parámetros que conforman cada proyecto en particular, ya que las propuestas guardan un mismo concepto general , poner sus ahorros a generar de forma segura, pero difieren en tiempos de inicio y finalización, en ubicación y en las superficies a construir y en el perfil del inversor, si lo que busca es renta fija o plusvalia.
"/>
        <QuestionAndAnswer question="¿Cómo me entero de los avances del proyecto?" answer="El seguimiento de los avances puede hacerse desde el perfil del usuario, tanto desde la web como desde la APP. Mensualmente compartimos los “Avances de Obra” de cada proyecto para que tengan conocimiento absoluto del estado de cada uno.
"/>
        <QuestionAndAnswer question="¿Cómo se calcula la rentabilidad?" answer="La rentabilidad es básicamente la ganancia entre precio de venta y costos (terreno + construcción), considerando el valor invertido y el tiempo de inversión.
"/>
            <QuestionAndAnswer question="¿Es seguro?" answer="Sí. El respaldo de la inversión está dado por el mismo proyecto.
"/>
            <QuestionAndAnswer question="¿Que pasa si suceden cuestiones monetarias macro económicas y los proyectos se ven afectados en su rentabilidad?" answer="Si esto sucede el capital invertido siempre esta asegurado por el mismo proyecto, al ser una inversion, como toda inversión conlleva sus riesgos macro económicos los cuales pueden afectar a los rendimientos pero nunca a su capital invertido."/>
            <QuestionAndAnswer question='¿Que sucede si se vence un Plazo de contrato?' answer='El inversor puede retirar su capital mas su ganancia, puede reinvertir o puede agregar capital para realizar un interes compuesto y así reanudar otro ciclo de inversión a 12 18 o 24 meses.'/>
            <QuestionAndAnswer question='Plazos de contrato' answer='Actualmente no hay ningún fondo para realizar inversion, el ultimo fondo se cerro en agosto del año 2023 el cual por la situación macroeconómica del país y la inestabilidad cambiaria otorgo un 2% de beneficios en USD bajo los 12% proyectados.'/>
            <QuestionAndAnswer question='¿Que sucede Si un plazo se vence pero el proyecto no obtuvo la rentabilidad esperada?' answer='El capital será devuelto al momento de finalizar el proyecto y los intereses serán devengados de acuerdo al resultado final.'/>
            <QuestionAndAnswer question='¿Son inversiones de primera o segunda línea?' answer='Todas las inversiones en nuestra plataforma son de segunda línea, a través de herramientas como criptomonedas.'/>
        </Flex>

    );
};

export default FAQs;