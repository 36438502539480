import React from 'react';
import {
    ButtonGroup,
    Flex,
    Heading,
    Text,
    Input,
    FormControl,
    FormLabel,
    NumberInput,
    NumberInputStepper,
    NumberInputField,
    NumberDecrementStepper, NumberIncrementStepper, Button, Image,
    useToast, Box, Link, FormErrorMessage, Select
} from "@chakra-ui/react";
import {FaRegCopy} from "react-icons/all";
import {useForm} from "react-hook-form";
import {authService, requestsService} from "../service/staticInstances";

function myFunction() {
    var copyText = " 0xfd0e7fc5c79fa680028292dfd291f1ce037734a6"
    navigator.clipboard.writeText(copyText);
    alert("Wallet copiada" );
}

const Deposit = () => {
    const toast = useToast();
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting }
    } = useForm()
    function onSubmit(values: any) {
        toast({
            title: 'Espera la acreditación de tus fondos',
            description: "Estamos confirmando tu pago. Cuando el pago esté aprobado, lo verás reflejado en tu cuenta",
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
        return new Promise((resolve) => {
            setTimeout(() => {
                // Aca iria la capa de servicio que le pega al endpoint con un post
                let minimum_term = 0;
                if(values['plan'] === 'Plan 12%'){
                    minimum_term = 1;
                } else if( values['plan'] === 'Plan 15% a 24'){
                    minimum_term = 2;
                }else{
                    minimum_term = 3;
                }
                console.log("corri")
                requestsService.requestDeposit(values['amount'], values['plan'], minimum_term)
                    .then((response)=> window.location.href = '/profile')
                    .catch((error)=> console.log(error));
                resolve(values)
            })
        })
    }

    return (
        <Flex direction="column" justify="center" align="center" my="1em" mx="50px" gap="4em">
            <Heading>Depositá USDT por la red de TRON (TRC-20)</Heading>
            <Flex direction="column" justify="center" align="center" gap={8}>
                <Flex justify={"center"} align="center" direction={["column", "column", "column", "row"]}>
                    <Flex marginLeft={5} direction="column" justify={"center"} align="center" >
                        <Heading size="md">Dirección de la billetera </Heading>
                        <Image src="/images/qr_pago.png" alt="dirección de depósito"/>
                        <Flex gap={5} borderRadius="lg" borderColor="black" border='1px' justify="center" align={"center"} m="auto" p={2}>
                            <Text textAlign="center" cursor="pointer" onClick={myFunction}>
                                TWmRCMNxsTEmGptCGirsUiRa2tCvDJajST
                            </Text>
                            <Box m="auto" cursor="pointer">
                                <FaRegCopy onClick={myFunction}/>
                            </Box>
                        </Flex>
                    </Flex>
                    <Flex mx="10" direction="column" align={"center"} justify="center" width={["80%", "50%", "50%", "50%"]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl  isInvalid={errors.amount !== undefined}>
                                <FormLabel>Escribí la cantidad de USDT que nos mandaste</FormLabel>
                                <Input width={["100%", "50%", "50%", "50%"]} type="number" step="0.1" bgColor="white"
                                       {...register('amount', {
                                           required: 'Campo obligatorio'
                                       })}>

                                </Input>
                                <Select width={["100%", "50%", "50%", "50%"]} placeholder='Elegí una opción'  {...register('plan', {
                                    required: 'Campo obligatorio'
                                })}>
                                    <option value='Plan 12%'>Plan corto plazo (12% 12 meses)</option>
                                    <option value='Plan 15% a 24'>Plan mediano plazo (15% 24 meses)</option>
                                    <option value='Plan 15% a 36'>Plan largo plazo (15% 36 meses)</option>
                                </Select>
                                <ButtonGroup  margin={10} alignItems="center" justifyItems={"center"}>
                                    <Link href="mailto:info@bancastokker.com">
                                        <Button colorScheme="blackAlpha">Necesito ayuda</Button>
                                    </Link>
                                    <Button colorScheme="red"
                                            type="submit"
                                            isLoading={isSubmitting}>Enviar</Button>
                                </ButtonGroup>
                            </FormControl>
                        </form>


                    </Flex>
                </Flex>


            </Flex>



        </Flex>
    );
};

export default Deposit;