import {Flex, Heading} from "@chakra-ui/react";
import theme from "../chakraTheme";

type Titles={
    redTitle:string,
    blackTitle:string
}
const RedAndBlackTitle = ({redTitle, blackTitle}:Titles) =>{

    return (
        <Flex direction="column" justify="left" my="2em">
            <Heading size="md" bgColor={theme.colors.accent} bgClip="text">{redTitle}</Heading>
            <Heading size="2xl">{blackTitle}</Heading>
        </Flex>
    );
}
export default RedAndBlackTitle;