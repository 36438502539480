import {paths} from "../macros/paths";
import axios from "axios";

export class RequestsService {

    public async requestWithdraw(amount:number, product_name:any) {
        return  await axios.post(paths.BASE_URL + '/withdraw', { amount:amount,
            product_name: product_name,
            withdrawal_date: Date.now(),
            email: window.localStorage.getItem("userEmail")}, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        })
    }

    public async requestDeposit(price:number, product_name:string, minimum_term:number) {
        return await axios.post(paths.BASE_URL + '/transaction',
            {
                email: window.localStorage.getItem("userEmail") ,
                price: price,
                product_name: product_name,
                category: 0,
                minimumterm:minimum_term
            },{
                headers: {
                    'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
                }
            });
    }


}
