import { Divider, Flex, Heading } from "@chakra-ui/react";
import { MiningOpportunitie } from "../components/MiningOpportunitie";
import RealEstateOpportunities from "../components/RealEstateOpportunities";
import VehicleOpportunities from "../components/VehicleOpportunities";

const Marketplace = () => {

    // useEffect(() => {
    //     fetch(paths.BASE_URL + '/cars', {
    //         method: 'GET',
    //         headers: {
    //             'Access-Control-Allow-Origin':'*',
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then((response) => {
    //             console.log(response)
    //             return response.json();
    //         })
    //         .then((data) => {
    //             setCars(data)
    //             console.log(cars);
    //             console.log(data);
    //
    //         })
    //         .then(() => {
    //
    //         })
    //         .catch(error => console.log('ERROR' + error))
    //     }, []);

    return (
        <Flex direction="column" justify="center" gap={10} align="center" my={5}>
            <Heading>Explorá nuestros proyectos</Heading>
            <RealEstateOpportunities/>
            <Divider marginTop={5} shadow="inner" width="60%" />
            <VehicleOpportunities />
            <Divider marginTop={5} shadow="inner" width="60%" />
            <MiningOpportunitie/>
            <Divider marginTop={5} shadow="inner" width="60%" />
        </Flex>
    );
};

export default Marketplace;