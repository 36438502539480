import React from 'react';
import {
    Box,
    Image,
    Flex,
    Heading,
    Text,
    Progress,
    Center,
    ButtonGroup,
    Button, Link
} from "@chakra-ui/react";
import {GoLocation} from "react-icons/all";
import theme from "../chakraTheme";
type CardProps = {
    blur:boolean,
    title:string,
    image:string,
    location:string,
    subtitle1:string,
    subtitle2:string,
    subtitle3:string,
    funded:number,
    link: string
}

const comingSoonBannerStyle = {
    backgroundColor: theme.colors.accent,
    color:'white',
    fontWeight: 'bold',
    padding: '5px'
}
const RealEstateCard = ({blur, title, image, location, subtitle1, subtitle2, subtitle3, funded, link}:CardProps) => {
    return (
        <Box sx={ blur ?  {'pointer-events': 'none'}: {} } >
            <Box zIndex={1} maxW='md' borderWidth='1px' borderRadius='lg' overflow='hidden' boxShadow="xl" filter='auto' blur={blur?'5px':'none'} backgroundColor="white">
                <Image src={image} w="100%" h="300px " zIndex={1}/>
                <Flex gap={3} px={6} py={3}>
                    <Heading size="md">{title}</Heading>
                    <Flex justify="end">
                        <GoLocation size="20px"/>
                    </Flex>
                    <Text fontWeight="semibold">{location}</Text>
                </Flex>
                <Flex direction="column" justify="left" p={6}>
                    <Text bgColor="#676767" bgClip="text"></Text>
                    <Text bgColor="#676767" bgClip="text">{subtitle2}</Text>
                    <Text bgColor="#676767" bgClip="text">{subtitle3}</Text>
                </Flex>
                <Center>
                    <Text fontWeight="bold">{funded}%</Text>
                </Center>
                <Progress colorScheme='red' size='sm' value={funded} />
                <Center my="2em">
                    <ButtonGroup>
                        <Link href={link} >
                            <Button colorScheme="red" >
                                <Text bgColor={theme.colors.primary} bgClip="text"  textDecoration="none">Más info</Text></Button>

                        </Link>
                        <Link href={link} textDecoration="none">
                            <Button variant="outline" colorScheme='red'  >
                                <Text bgColor={theme.colors.accent} bgClip="text"  textDecoration="none">
                                    Invertir
                                </Text>
                            </Button>
                        </Link>
                    </ButtonGroup>
                </Center>
            </Box>
            {blur?
            <Center mt="-15em" zIndex={2} >
                <Heading  sx={ comingSoonBannerStyle} as="span" size="lg" >Proximamente</Heading>
            </Center>
                :<Box display="none" mt="-15em" ></Box> }

        </Box>

    );
};

export default RealEstateCard;