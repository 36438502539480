
import LoginCard from "../components/LoginCard";
import {Button, Flex} from "@chakra-ui/react";
import AboutBancaStokker from "../components/AboutBancaStokker";
import RealEstateBanner from "../components/RealEstateBanner";
import ExploreOurProjects from "../components/ExploreOurProjects";
import RealEstateBenefits from "../components/RealEstateBenefits";

const Login = () => {
    return (
        <Flex direction="column" pb="10em">
            <LoginCard/>
        </Flex>
    )
}

export default Login;


// const Login = () => {
//     return (
//         <div className="w-screen" style={{ backgroundColor: "#FBFBFB" }}>
//             <form className="bg-white shadow-md rounded-lg px-8 mt-16 pt-6 ml-64 mr-64 pb-8 mb-4">
//                 <div className="mb-4">
//                     <h1 className="font-bold text-xl mb-4">Accedé a tu cuenta</h1>
//                     <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="username">
//                         Email
//                     </label>
//                     <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Ingresá tu dirección de email" type="text" placeholder="Ingresá tu dirección de email" />
//                 </div>
//                 <div className="mb-6">
//                     <label className="block text-gray-700 text-md font-bold mb-2" htmlFor="password">
//                         Contraseña
//                     </label>
//                     <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="Ingresá tu contraseña" />
//                     {/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
//                 </div>
//                 <div className="flex items-center justify-between">
//                     <div className="flex items-center mb-4">
//                         <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-300 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
//                         <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-700">Recuérdame</label>
//                     </div>
//                     {/* Aca deberia ir un link a la pagina que permite crear una nueva contraseña */}
//                     <h1 className="inline-block align-baseline font-bold text-sm text-blue-600 hover:text-blue-800" ref="#">Olvidé mi contraseña</h1>
//                 </div>
//                 <button className="text-white w-full h-10 font-semibold rounded mt-2 mb-2" style={{backgroundColor:"#e53e3e"}}>Iniciar sesión</button>
//                 <div className="flex flex-row mt-4">
//                     <h1 className="mr-2">Todavía no tenés una cuenta?</h1>
//                     <Link to="/register" className="text-blue-600 font-bold hover:text-blue-800">Registrate</Link>
//                 </div>
//             </form>
//         </div>
//     );
// }
// export default Login;