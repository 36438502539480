import { extendTheme, theme as baseTheme} from '@chakra-ui/react'
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
    fonts: {
        heading: `'Roboto Slab', serif`,
        body: `'Inter', sans-serif`,
    },
    colors: {
        accent: baseTheme.colors.red["500"],
        primary: baseTheme.colors.gray["50"],
        secondary: baseTheme.colors.gray["800"],
        whiteButtons:baseTheme.colors.whiteAlpha["900"]
    },
    styles: {
        global: (props) => ({
            body: {
                bg: mode(theme.colors.primary, theme.colors.primary)(props),
                color: theme.colors.secondary
            }
        }),
    },
})

export default theme