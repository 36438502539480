import React from 'react';
import {
    TabList,
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    Center,
    Text, Heading, OrderedList, ListItem, Wrap, WrapItem
} from "@chakra-ui/react";
import theme from "../chakraTheme";
const tabPanelStyle ={
    backgroundColor: theme.colors.gray["100"]
}
const tabs = {
    backgroundColor: 'white',
}

type Props = {
    desc: string;
    ubc: string;
    fin: string;
}

const InfoTabs = ({desc, ubc, fin}:Props) => {
    return (
        <Tabs isFitted colorScheme="red" mt="3em"  sx={tabPanelStyle} rounded="lg" defaultIndex={1}>
            <TabList mb='1em' sx={tabs} flexDirection={["column", "column", "row", "row"]} >
                <Tab>Descripción</Tab>
                <Tab>Ubicación</Tab>
                    <Tab display={{base:'none', sm:'inline'}}>Análisis Financiero</Tab>
                    <Tab>Términos y condiciones</Tab>
            </TabList>
            <TabPanels>
                <TabPanel >
                    <p>{desc}</p>
                </TabPanel>
                <TabPanel>
                    <Wrap justify="center">
                        <WrapItem>
                            <Center>
                                <iframe  title="google map" width="600" height="500" id="gmap_canvas"
                                         src={ubc}
                                         frameBorder="0" scrolling="no"/>
                            </Center>
                        </WrapItem>
                        <WrapItem>
                            <Center>
                                <Text mt="2em" mx="2em">
                                    {fin}
                                </Text>
                            </Center>
                        </WrapItem>
                    </Wrap>


                </TabPanel>
                <TabPanel >
                    <Heading>
                        Escenarios de rentabilidad
                    </Heading>
                    <Text>
                        Las unidades quedarán a tu nombre. Cada unidad de pozo valdrá $40.000 Usd o 400 acciones. Sin embargo desde el día 1 en esta opción cobrarás una renta fija como si el apartamento ya estuviera terminado generando dinero y ocupado ¡Pero sin generar desgaste alguno! Hoy en día 22/07/2022 la renta es de $35.000 por mes. ajustable a la ley de alquileres.
                    </Text>

                </TabPanel>
                <TabPanel>
                    <Heading>Debe aceptar estos términos para invertir en esta propiedad</Heading>
                    <OrderedList>
                        <ListItem>La rentabilidad dependerá del resultado comercial del proyecto hasta su término. </ListItem>
                        <ListItem>En la etapa de financiamiento (la actual), para reservar tus acciones deberás abonar el 10% de su inversión, este abono es equivalente a una promesa de compra.</ListItem>
                        <ListItem>El proyecto se activará solo si se llega al 100% de financiamiento. En caso contrario podrás cambiar tus fondos de proyecto o pedir un reembolso total de tu abono del 10%, SIN DESCUENTOS NI COMISIONES.</ListItem>
                        <ListItem>Una vez financiado el proyecto tenemos un plazo de 30 días de corrido para hacerte llegar el contrato de compraventa de acciones, el cual se firmará digitalmente a través de la plataforma Docusign. </ListItem>
                        <ListItem>Una vez financiado el proyecto, tendrás 20 días de corrido para depositar el saldo del 90% de tu inversión</ListItem>
                    </OrderedList>
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default InfoTabs;