import { paths } from "../macros/paths";
import axios from "axios";
import { FaAlgolia } from "react-icons/fa";



export class ProfileService {

    public async getBalance() {
        return await axios.post(paths.BASE_URL + '/profile/balances', { email: window.localStorage.getItem("userEmail") }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        })
    }

    public async getDistribution() {
        return await axios.post(paths.BASE_URL + '/profile/distribution', { email: window.localStorage.getItem("userEmail") }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        })
    }
    public async getTransactions() {
        const algo = await axios.post(paths.BASE_URL + '/profile/transaction', { email: window.localStorage.getItem("userEmail") }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        })
        console.log(algo)
        return algo
    }
    public async getWithdrawals() {
        return await axios.post(paths.BASE_URL + '/profile/withdrawal', { email: window.localStorage.getItem("userEmail") }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        })
    }
    public async getBalanceList() {
        return await axios.post(paths.BASE_URL + '/profile/getMyBalanceList', { email: window.localStorage.getItem("userEmail") }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }})
    }

}

