import React from 'react';
import {Text, Slider, SliderTrack, SliderFilledTrack, Tooltip, SliderThumb, Flex, Heading} from "@chakra-ui/react";
import {SimulatorProps} from "../types/types";

const SliderWithToolTip = ({roi, months}:SimulatorProps) => {
    const [sliderValue, setSliderValue] = React.useState(1000)
    const [showTooltip, setShowTooltip] = React.useState(false)
    return (
        <Flex direction="column">
            <Text  mx="auto">En {months} meses ganarás</Text>
            <Heading  mx="auto"> {sliderValue + sliderValue*roi/100 } USD</Heading>
            <Slider
                id='slider'
                defaultValue={1000}
                min={0}
                max={20000}
                colorScheme='red'
                onChange={(v) => setSliderValue(v)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                mt={10}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <Tooltip
                    hasArrow
                    bg='red.500'
                    color='white'
                    placement='top'
                    isOpen={showTooltip}
                    label={`Invertís ${sliderValue} USD`}
                >
                    <SliderThumb />
                </Tooltip>
            </Slider>
            <Flex justify="end">
                <Text>Invertís {sliderValue} USD</Text>
            </Flex>
        </Flex>


    );
};

export default SliderWithToolTip;