import React from 'react';
import {Flex, Heading, Text} from "@chakra-ui/react";
import SliderWithToolTip from "./SliderWithToolTip";
import {SimulatorProps} from "../types/types";

const InvestmentPrediction = ({roi, months}: SimulatorProps) => {
    return (
        <Flex direction="column" justify="center">
            <SliderWithToolTip roi={roi} months={months}/>
        </Flex>
    );
};

export default InvestmentPrediction;