import React, { useEffect, useState } from 'react';
import {
    Flex,
    Heading,
    Center,
    WrapItem,
    Wrap,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TableContainer, Table, Thead, Tr, Th, Tbody, Td, Text, Badge, Link, Button
} from "@chakra-ui/react";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Chart,
    Legend,
    LinearScale,
    LineElement,
    PointElement, Title,
    Tooltip
} from "chart.js";
import { Line, Pie } from 'react-chartjs-2';
import { authService, profileService } from "../service/staticInstances";
import { months } from "../utils/utils";
import { FaArrowRight } from "react-icons/all";

Chart.register(ArcElement, Tooltip, Legend); //pie
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
); //line
const InvestingProfile = () => {
    const labelsLineChart = months({ count: 12 })
    const [balance, setBalance] = useState({
        labels: labelsLineChart,
        datasets: [{
            label: 'Tus fondos',
            data: [1000, 1100, 1200, 1500, 1400, 1600, 5000],
            fill: false,
            borderColor: 'rgb(245,19,85)',
            tension: 0.1
        }]
    });
    const [distribution, setDistribution] = useState({
        labels: ['No data'],
        datasets: [
            {
                label: 'Distribución de inversión',
                data: [100],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',

                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ]
    });
    const [transactions, setTransactions] = useState([]);
    const [withdrawals, setWithdrawals] = useState([]);
    const [balanceList, setBalanceList] = useState([]);

    useEffect(() => {
        profileService.getBalance().then((response) => {
            setBalance({
                labels: labelsLineChart,
                datasets: [{
                    label: 'Tus fondos',
                    data: response.data,
                    fill: false,
                    borderColor: 'rgb(245,19,85)',
                    tension: 0.1
                }]
            })
        })
    }, [])

    useEffect(() => {
        profileService.getDistribution().then((response) => {

            let receivedData = response.data;
            let labels: string[] = [];
            let data: number[] = [];
            if (receivedData !== undefined) {
                receivedData.forEach((element: any) => {
                    labels.push(element.plan === 1 ? "Plan 12% a 12 meses" : (element.plan === 2 ? "Plan 15% a 24 meses" : "Plan 15% a 36 meses"));
                    data.push(element.distribution * 100);
                });
                setDistribution(
                    {
                        labels: labels,
                        datasets: [
                            {
                                label: 'Distribución de inversión',
                                data: data,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                ],
                                borderWidth: 1,
                            },
                        ]
                    }
                )
            }
        })
    }, [])

    useEffect(() => {
        profileService.getBalanceList().then((response) => {
            setBalanceList(response.data)
        })
    },[]);

    useEffect(() => {
        profileService.getTransactions().then((response) => {
            setTransactions(response.data)
        })
    }, [])

    console.log(transactions);


    useEffect(() => {
        profileService.getWithdrawals().then((response) => {
            setWithdrawals(response.data)
        })
    }, [])

    return (
        <Flex justify="center" direction="column" my="2em">
            <Center>
                <Heading textAlign="center">Bienvenido de vuelta</Heading>
            </Center>
            <Wrap justify="center" spacing={10} m="10"  >
                <WrapItem>
                    <Flex bgColor="white" borderRadius="lg" justify="center" alignItems="center" h="350px" p={5}>
                        <Flex w={{ base: "250px", sm: "600px" }} justify="center" bgColor="white">
                            <Line options={
                                {
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top' as const,
                                        },
                                        title: {
                                            display: true,
                                            text: 'Mirá tu progreso',
                                        },
                                    },
                                }
                            } data={balance} />
                        </Flex>
                    </Flex>
                </WrapItem>
                <WrapItem>
                    <Flex bgColor="white" borderRadius="lg" justify="center" p={10} h="350px">
                        <Flex justify="center" w={{ base: "200px", sm: "300px" }} bgColor="white">
                            <Pie data={distribution} options={{
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Distribución de inversiones'
                                    }
                                }
                            }} />
                        </Flex>
                    </Flex>
                </WrapItem>
            </Wrap>
            {/*/ ------------------------------------------------------- TRANSACTIONS /*/}
            <Flex direction="column" bgColor="white" borderRadius="lg" mx="10" p={5}>
                <Heading size="lg">Tus transacciones</Heading>
                <Tabs isFitted colorScheme="red" rounded="lg" defaultIndex={0}>
                    <TabList mb='1em' flexDirection={["column", "column", "row", "row"]}>
                        <Tab>Historial de Balances</Tab>
                        <Tab>Todas</Tab>
                        <Tab>Entrantes</Tab>
                        <Tab>Salientes</Tab>
                    </TabList>
                    <TabPanels>

                        <TabPanel>
                            <Center>
                                <TableContainer w="100%" borderRadius="lg">
                                    <Table size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Nombre del producto</Th>
                                                <Th>Fecha</Th>
                                                <Th>Estado</Th>
                                                <Th>Monto</Th>
                                                <Th>Interés Acumulado</Th>
                                                <Th>Min. Term.</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                balanceList.map((column: any) => {
                                                    return (
                                                        <Tr>
                                                            <Td>{column.productname}</Td>
                                                            <Td>{column.creationdate}</Td>
                                                            <Td>{column.status === 0 ? 'Pendiente' : 'Confirmada'}</Td>
                                                            <Td><Text>{column.price}</Text> USD</Td>
                                                            <Td><Text>{column.accumulated_interest} USD</Text></Td>
                                                            <Td>{column.minimumterm}</Td>
                                                        </Tr>
                                                    )
                                                }

                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Center>
                        </TabPanel>

                        <TabPanel>
                            <Center>
                                <TableContainer w="100%" borderRadius="lg">
                                    <Table size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Fecha</Th>
                                                <Th>Estado</Th>
                                                <Th>Monto</Th>
                                                <Th>Dirección</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                transactions.map((column: any) => {
                                                    return (
                                                        <Tr>
                                                            <Td>{column.creationdate}</Td>
                                                            <Td>{column.status === 0 ? 'Pendiente' : 'Confirmada'}</Td>
                                                            <Td><Text>{column.price}</Text> USD</Td>
                                                            {column.direction === 0 ?
                                                                <Td><Badge colorScheme="green">Entrante</Badge></Td> :
                                                                <Td><Badge colorScheme="red">Saliente</Badge></Td>}
                                                        </Tr>
                                                    )
                                                }

                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Center>
                        </TabPanel>

                        <TabPanel>
                            <Center>
                                <TableContainer w="100%" borderRadius="lg">
                                    <Table size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Fecha</Th>
                                                <Th>Estado</Th>
                                                <Th>Monto</Th>
                                                <Th>Dirección</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                transactions.filter(transaction => transaction.direction === 0).map((column: any) => {
                                                    return (
                                                        <Tr>
                                                            <Td>{column.creationdate}</Td>
                                                            <Td>{column.status === 0 ? 'Pendiente' : 'Confirmada'}</Td>
                                                            <Td><Text>{column.price}</Text> USD</Td>
                                                            {column.direction === 0 ?
                                                                <Td><Badge colorScheme="green">Entrante</Badge></Td> :
                                                                <Td><Badge colorScheme="red">Saliente</Badge></Td>}
                                                        </Tr>
                                                    )
                                                }

                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Center>
                        </TabPanel>

                        <TabPanel>
                            <Center>
                                <TableContainer w="100%" borderRadius="lg">
                                    <Table size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Fecha</Th>
                                                <Th>Estado</Th>
                                                <Th>Monto</Th>
                                                <Th>Dirección</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                transactions.filter(transaction => transaction.direction === 1).map((column: any) => {
                                                    return (
                                                        <Tr>
                                                            <Td>{column.creationdate}</Td>
                                                            <Td>{column.status === 0 ? 'Pendiente' : 'Confirmada'}</Td>
                                                            <Td><Text>{column.price}</Text> USD</Td>
                                                            {column.direction === 0 ?
                                                                <Td><Badge colorScheme="green">Entrante</Badge></Td> :
                                                                <Td><Badge colorScheme="red">Saliente</Badge></Td>}
                                                        </Tr>
                                                    )
                                                }

                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Center>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
            {/*/ ------------------------------------------------------- Withdrawal Table-------------- /*/}
            <Flex direction="column" bgColor="white" borderRadius="lg" m="10" p={5}>
                <Heading size="lg">Períodos de retiro</Heading>
                <Center>
                    <TableContainer w="100%" borderRadius="lg">
                        <Table size="sm">
                            <Tbody>
                                {withdrawals.map((withdrawal) => {
                                    let date = new Date(withdrawal.withdrawal_date);
                                    return (
                                        <Tr>
                                            <Td>Podés retirar</Td>
                                            <Td>{withdrawal.amount}</Td>
                                            <Td ><Text>a partir del</Text></Td>
                                            <Td>{withdrawal.withdrawal_date}</Td>
                                            <Td>
                                                <Link href={"/withdraw?product=" + withdrawal.product_name + "&amount=" + withdrawal.amount}>
                                                    {date <= Date.now() ? <Button rightIcon={<FaArrowRight />} colorScheme='red' >
                                                        Retirar
                                                    </Button> : <Button rightIcon={<FaArrowRight />} colorScheme='red' isDisabled>
                                                        Retirar
                                                    </Button>}

                                                </Link>
                                            </Td>
                                        </Tr>);
                                }
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Center>
            </Flex>
        </Flex>
    );

};

export default InvestingProfile;