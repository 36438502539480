import React from 'react';
import { ButtonGroup, Text, Flex, Button, Heading, Image, Link, Wrap, WrapItem } from "@chakra-ui/react";
import InfoTabs from "../components/InfoTabs";
import theme from "../chakraTheme";

export const RealEstateProjectMall = () => {
    return (
        <Flex direction="column" mx={50} my={10}>
            <Wrap justify={{ base: "center", sm: "left" }}>
                <WrapItem>
                    <Heading textAlign="center">Polo Comercial</Heading>
                </WrapItem>
                <WrapItem>
                    <ButtonGroup mx={5} justifyItems="center" alignItems={"center"} flexDirection={["column", "row", "row", "row"]}>
                        <Link href="/deposit" width={"100%"}>
                            <Button width={"100%"} colorScheme="red"><Text color={theme.colors.primary}>Invertir</Text></Button>
                        </Link>
                        <Button marginTop={2} width={"100%"} variant="outline" colorScheme="black" onClick={myFunction}><Text color={theme.colors.secondary}>Compartir</Text></Button>
                    </ButtonGroup>
                </WrapItem>
            </Wrap>
            <Wrap justify="center" align={"center"}>
                <WrapItem width={"auto"} mx="auto" flexDirection={["column", "column", "column", "row"]}>
                    <Image src="/images/polo_comercial.jpeg" w="100%" h={["10rem", "450px", "450px", "450px"]} mr={8} mt={10}/>
                    <Image src={"/images/polo_comercial_1.jpeg"} w="100%" h={["200px", "450px", "450px", "450px"]} mr={8} mt={10}/>
                </WrapItem>
            </Wrap>
            <InfoTabs desc="Polo comercial para diversos emprendimientos. Punto estratégico para la zona de estancia grande calabacilla puerto yerua y Nueva Escocia. Contará con Indumentaria, electrónica, gastronomía, diversión.
                        A continuación presentamos un diagrama de desarrollo del proyecto:" ubc="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d54426.93279063153!2d-58.17032104716337!3d-31.50538785241212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d-31.509195!2d-58.135591999999995!5e0!3m2!1sen!2sar!4v1674786175040!5m2!1sen!2sar" fin="Polo comercial para diversos emprendimientos. Punto estratégico para la zona de estancia grande calabacilla puerto yerua y Nueva Escocia. Contará con Indumentaria, electrónica, gastronomía, diversión." />
        </Flex>
    );
}; 


function myFunction() {    
    const copyText = document.location.href;
    console.log(copyText);
    navigator.clipboard.writeText(document.location.href).then(() => {
        alert("Link Copiado!")
    });
    alert("Link Copiado!");
}

export default RealEstateProjectMall;