import theme from "../chakraTheme";
import {Box, Button, Flex, Heading, Image, Link, Text} from "@chakra-ui/react";
import { FaArrowRight} from "react-icons/all";

const MiningBanner = () => {
    return (
        <Flex align="center" justify="space-around" bgColor={theme.colors.secondary} px={50} py={30}>
    <Flex direction="column" gap={10}>
    <Flex direction={{base:"column", sm:"row"}} >
    <Heading as="span" mr="7px" color={theme.colors.primary} >
        Minería de
    </Heading>
    <Heading color={theme.colors.accent}  as="span"> criptomonedas</Heading>
    </Flex>
        <Text color={theme.colors.primary} w="100%">Nuestra empresa cuenta con granjas de minado propias y asociaciones directas a diferentes granjas en los países que tenemos presencia. Para hacer partícipes a nuestros inversores elegimos distribuir las ganancias de la minería entre las partes (Granjas e inversores). </Text>
    <Link href="/marketplace">
        <Button bgColor={theme.colors.whiteButtons} rightIcon={<FaArrowRight color={theme.colors.accent} />} w={{base: " 75%", sm:"20%"}}>
            <Text bgColor={theme.colors.accent} bgClip="text">Ir al Marketplace</Text>
        </Button>
    </Link>

    </Flex>
            <Box display={{base:'none', sm:'inline'}}>
                <Image src="/images/rig.png" w="400px"/>
            </Box>
        </Flex>
    );
};

export default MiningBanner;