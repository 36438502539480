import React from 'react';
import {Center, Flex, Heading, Image, Link, Text, Wrap, WrapItem} from "@chakra-ui/react";
import theme from "../chakraTheme";
type CategoryCardProps = {
    image:string,
    title:string,
    roi:string,
    description:string,
    reverse:boolean,
    link:string
}

const CategoryCard = ({image, title,roi,description,reverse, link}:CategoryCardProps) => {
    return (
        <Wrap direction={reverse ? 'row-reverse': 'row' } boxShadow="xl" bgColor="white" borderRadius="lg" my={10} justify="space-around">
            <WrapItem>
            <Image src={image} maxH="550px"/>
            </WrapItem>
            <WrapItem>
                <Flex direction="column"  justify="center" p={3}>
                    <Center>
                        <Heading>{title}</Heading>
                    </Center>
                    <Text w={{base:"250px", sm:"350px"}} textAlign="center">{description}</Text>
                    <Center>
                        <Flex direction="column">
                            <Text fontWeight="bold" fontSize="6xl">{roi}</Text>
                            <Center><Text>Rentabilidad Esperada</Text></Center>

                        </Flex>
                    </Center>
                    <Center mt={10}>
                        <Link color={theme.colors.accent} fontSize="2xl" fontWeight="bold" href={link}>+ INFO</Link>
                    </Center>
                </Flex>
            </WrapItem>

        </Wrap>
    );
};

export default CategoryCard;