import {useForm, useFormState} from 'react-hook-form'
import {
    FormLabel,
    FormControl,
    Input,
    Button,
    Text,
    Center,
    Flex,
    Spacer,
    FormErrorMessage,
    Checkbox
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { authService } from '../service/staticInstances'

const LoginCard = () => {
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        setError
    } = useForm()

    function onSubmit(values: any) {
        return new Promise((resolve) => {
            setTimeout(() => {

                authService.login(values['email'], values['password'])
                    .then(
                        (response)=>
                        {

                        })
                    .catch(
                        (error)=> {
                            console.log(error)
                           setError('email',{type:'custom', message: 'aa' } )
                            console.log(errors)
                        }
                    );
                resolve(values)
             })
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Center >
                <Flex direction={"column"} boxShadow="xl" bgColor="white" mt={10} p={8} rounded='lg'>
                    <FormControl isInvalid={errors.email !== undefined}>
                        <Flex direction={"column"}>
                            <Text fontSize="lg" fontWeight="bold">Accedé a tu cuenta</Text>
                            <FormLabel htmlFor='email' mt={3}>Email</FormLabel>
                            <Input
                                id='email'
                                type='email'
                                placeholder='Ingresá tu dirección de email'
                                {...register('email', {
                                    required: 'Campo obligatorio',
                                    minLength: { value: 4, message: 'Ingresá un email válido' },
                                })}
                            />
                            <FormLabel htmlFor='password'>Contraseña</FormLabel>
                            <Input
                                id='password'
                                type='password'
                                placeholder='Ingresá tu contraseña'
                                {...register('password', {
                                    required: 'Campo obligatorio',
                                })}
                            />
                            <FormErrorMessage padding={3}>
                                {errors.email !== null ?' Email o contraseña equivocada.': ' ' }

                            </FormErrorMessage>
                        </Flex>
                    </FormControl>
                    <Flex direction={"row"}>
                        <Checkbox defaultChecked size={"sm"}>Recuérdame</Checkbox>
                        <Spacer />
                    </Flex>
                    <Button mt={4} colorScheme='red' isLoading={isSubmitting} type='submit'>
                        Iniciar Sesión
                    </Button>
                    <Center>
                        <Text fontSize={"small"}>Todavía no tenés una cuenta?</Text>
                        <Link to="/register">
                            <Text ml="1" fontWeight="semibold" color={"#3757ff"} fontSize={"small"}>Registrate</Text>
                        </Link>
                    </Center>
                </Flex>
            </Center>
        </form>
    )
}

export default LoginCard;