import React from 'react';
import { Flex,} from "@chakra-ui/react";
import RealEstateBanner from "../components/RealEstateBanner";
import RealEstateBenefits from "../components/RealEstateBenefits";
import ExploreOurProjects from "../components/ExploreOurProjects";

const RealEstateLanding = () => {
    return (
        <Flex direction="column" justify="center" gap={10}>
            <RealEstateBanner/>
            <ExploreOurProjects/>
            <RealEstateBenefits/>
        </Flex>

    );
};

export default RealEstateLanding;