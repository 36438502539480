import React from 'react';
import InvestingSimulator from "../components/InvestingSimulator";
import ThreeTierPricing from "./InvestInBancaStokker";
import {Flex} from "@chakra-ui/react";

const SimulatorView = () => {
    return (
        <>
            <ThreeTierPricing/>
            <Flex mx={50} mb={5} justify={"center"}>
                <InvestingSimulator/>
            </Flex>
        </>
    );
};

export default SimulatorView;