import React from 'react';
import RedAndBlackTitle from "./RedAndBlackTitle";
import {
    Box,
    Table,
    TableContainer,
    Td,
    Th,
    Thead,
    Tr,
    Tbody,
    Center,
    Text,
    Badge
} from "@chakra-ui/react";
import theme from "../chakraTheme";
const RoiTable = () => {
    return (
        <Box mx="50px" >
            <RedAndBlackTitle redTitle="Rentabilidad" blackTitle="12-24% en dólares"/>
            <Center my="2em">
                <TableContainer boxShadow="lg" p={5} w="100%" borderRadius="lg">
                    <Table variant='unstyled' size="sm">
                      <Thead>
                            <Tr>
                                <Th>Tipo de Inversión</Th>
                                <Th>Riesgo</Th>
                                <Th>Plazos</Th>
                                <Th>Rentabilidad*</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>Fuerza de minado</Td>
                                <Td><Badge colorScheme="red">Medio</Badge></Td>
                                <Td ><Text>Retiros a fin de contrato, tanto ganancia como capital invertido. Consultar si hay MHZ disponible.</Text></Td>
                                <Td><Text>18%-20%  anual en USD.</Text></Td>
                            </Tr>
                            <Tr>
                                <Td>Desarrollo Inmobiliario</Td>
                                <Td><Badge colorScheme="green">Bajo/Conservador</Badge></Td>
                                <Td><Text>Por año o proyecto. Recomendado para inversores nuevos y conservadores. </Text></Td>
                                <Td><Text>12-24% anual fijo en USD.</Text></Td>
                            </Tr>
                            <Tr>
                                <Td>Vehículos</Td>
                                <Td><Badge colorScheme="orange">Medio-Bajo</Badge></Td>
                                <Td><Text>Tiempo entre compra  y venta de vehículos.</Text></Td>
                                <Td><Text>10-20% en el plazo en USD.</Text></Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Center>
            <Text as="i" bgColor={theme.colors.secondary} bgClip="text">*La rentabilidad dependerá del resultado comercial del proyecto hasta su término</Text>
        </Box>
         );
}

export default RoiTable;