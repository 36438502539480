import { AuthService } from "./AuthService";
import {ProfileService} from "./ProfileService";
import {InvestmentOpService} from "./InvestmentOpService";
import {RequestsService} from "./RequestsService";

const authService = new AuthService();
const profileService = new ProfileService();
const investmentOpService = new InvestmentOpService();
const requestsService = new RequestsService();

export {
    authService,
    profileService,
    investmentOpService,
    requestsService
}