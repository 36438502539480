import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text
} from "@chakra-ui/react";
import RedAndBlackTitle from "./RedAndBlackTitle";

const InvestmentOpportunities = () => {
    return (
        <Box mx="50px" mt={{base: "1em", sm:"-12em"}}>
          <RedAndBlackTitle redTitle="Oportunidades de inversión" blackTitle="¿En qué invertimos?"/>
            <Accordion allowToggle mr="10%" mt="2em">
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                <Text fontSize="xl" fontWeight="semibold" p={2}>Desarrollos Inmobiliarios</Text>

                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        Inversiones con garantía inmobiliaria al alcance de todos Participa en proyectos inmobiliarios de alta rentabilidad y respaldo tangible, completamente online. Inverti en ladrillo y protege tus ahorros.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <h2>
                        <AccordionButton >
                            <Box flex='1' textAlign='left'>
                                <Text fontSize="xl" fontWeight="semibold"  p={2}>Minería de criptomonedas</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        Nuestra empresa cuenta con granjas de minado propias y asociaciones directas a diferentes granjas en los países que tenemos presencia. Para hacer partícipes a nuestros inversores elegimos distribuir las ganancias de la minería entre las partes (Granjas e inversores).
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton >
                            <Box flex='1' textAlign='left'>
                                <Text fontSize="xl" fontWeight="semibold" p={2}>Compra/venta de vehículos de alta gama</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        Como jamás antes habías pensado, hoy podes ver nuestra flota de vehículos de alta gama que tenemos disponible para colateralizar nuestros proyectos o para comercializar, està atento a este sector porque frecuentemente aparecen excelentes oportunidades de compra de vehiculos que consideramos que están por debajo de su valor y las ganancias suelen ser muy buenas en el corto plazo!
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>

    );

}
export default InvestmentOpportunities;