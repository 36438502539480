import React from 'react';
import {Tabs, Tab, TabList, TabPanels, TabPanel, Button, Flex, Heading, Text, Center, Link} from "@chakra-ui/react";
import InvestmentPrediction from "./InvestmentPrediction";

const InvestingSimulator = () => {
    return (
        <Flex direction="column">
            <Flex justify="center">
                <Heading>Simulador</Heading>
            </Flex>
            <Tabs colorScheme="red">
                <TabList>
                    <Tab>Plan corto plazo</Tab>
                    <Tab>Plan mediano plazo</Tab>
                    <Tab>Plan largo plazo</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <InvestmentPrediction roi={12} months={12}/>
                        <Text>Retira capital mas ganancias al terminar el contrato.
                        </Text>
                    </TabPanel>
                    <TabPanel>
                        <InvestmentPrediction roi={15} months={24}/>
                        <Text>Retira capital mas ganancias al terminar el plazo.
                        </Text>
                    </TabPanel>
                    <TabPanel>
                        <InvestmentPrediction roi={15} months={36}/>
                        <Text>Retira semestralmente ganancias sin costo. Capital al terminar el plazo
                        </Text>
                    </TabPanel>
                </TabPanels>
                <Center>
                    <Link href="/deposit">
                        <Button colorScheme="red"><Text color="white">Invertir</Text></Button>
                    </Link>
                </Center>
            </Tabs>
        </Flex>
    );
};

export default InvestingSimulator;