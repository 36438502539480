import React from 'react';
import { ButtonGroup, Text, Flex, Button, Heading, Image, Link, Wrap, WrapItem } from "@chakra-ui/react";
import InfoTabs from "../components/InfoTabs";
import theme from "../chakraTheme";

const RealEstateProject = () => {
    return (
        <Flex direction="column" mx={50} my={10}>
            <Wrap justify={{ base: "center", sm: "left" }}>
                <WrapItem>
                    <Heading textAlign="center">Condominios Chicago</Heading>
                </WrapItem>
                <WrapItem>
                    <ButtonGroup mx={5} justifyItems="center" alignItems={"center"} flexDirection={["column", "row", "row", "row"]}>
                        <Link href="/deposit" width={"100%"}>
                            <Button width={"100%"} colorScheme="red"><Text color={theme.colors.primary}>Invertir</Text></Button>
                        </Link>
                        <Button marginTop={2} width={"100%"} variant="outline" colorScheme="black" onClick={myFunction}><Text color={theme.colors.secondary}>Compartir</Text></Button>
                        <Link href="https://bit.ly/3gnJWJa" width={"100%"}>
                            <Button width={"100%"} marginTop={2} variant="outline" colorScheme="black"><Text color={theme.colors.secondary}>Mas imagenes</Text></Button>
                        </Link>
                    </ButtonGroup>
                </WrapItem>
            </Wrap>
            <Wrap justify="center" align={"center"}>
                <WrapItem width={"auto"} mx="auto" flexDirection={["column", "column", "column", "row"]}>
                    <Image src="/images/CondominiosChicago.jpeg" w="100%" h={["10rem", "450px", "450px", "450px"]} mr={8} mt={10}></Image>
                    <Image src={"/images/progress.jpeg"} w="100%" h={["200px", "450px", "450px", "450px"]} mr={8} mt={10} />
                    <Image src={"/images/nivelado.jpeg"} w="100%" h={["200px", "450px", "450px", "450px"]} mr={8} mt={10} />
                </WrapItem>
            </Wrap>
            <InfoTabs desc="El proyecto 'Condominios Chicago' es un proyecto de préstamo a plazo y rentabilidad fija, con garantía de cuotaparte de sociedad o documento. Los fondos de este proyecto serán usados para construir el proyecto inmobiliario.
                        El proyecto que se apoyará con este financiamiento corresponde a la construcción, urbanización y venta de 33 departamentos de entre 38 y 44mt 4 en un terreno de 1100mt ubicado en Av Chajari de la Ciudad de Concordia Entre Rios.
                        La inversion sera de 24 meses y se comenzara a cobrar dividendos a partir de el mes 12!
                        Como siempre, al invertir pasarás a formar parte de una sociedad por acciones, creada para agrupar a los inversionistas, la cual ejecutará el préstamo a través un mutuo notarial a la desarrolladora. A su vez, la desarrolladora dejará como respaldo diferentes garantias.
                        A continuación presentamos un diagrama de desarrollo del proyecto:" ubc="https://maps.google.com/maps?q=1548%20Chajar%C3%AD,%20Concordia&t=&z=15&ie=UTF8&iwloc=&output=embed" fin="Condominios Chicagos será el desarrollo inmobiliario más atractivo moderno y cómodo de la ciudad de Concordia  para los jóvenes que quieren independizarse o simplemente venir a estudiar alguna de las carreras que ofrece la ciudad. Será un ecosistema excelente para el bienestar de las personas que vivan en el mismo ya que contarà con amenidades como piscina propia de 10x3, lavandería, gimnasio, SUM, Quincho y 33 departamentos de entre 38mt2 y 42mt2 con balcón y vista al norte. También contará con 4 locales comerciales de excelente diseño." />
        </Flex>
    );
};
function myFunction() {    
    const copyText = document.location.href;
    console.log(copyText);
    navigator.clipboard.writeText(document.location.href).then(() => {
        alert("Link Copiado!")
    });
    alert("Link Copiado!");
}

export default RealEstateProject;