import React from 'react';
import {Center, Flex, Heading,  ListItem, UnorderedList} from "@chakra-ui/react";


const RealEstateBenefits = () => {
    return (
        <Flex justify="center" direction="column" mx={50} mb="50px">
            <Center mb={5}>
                <Heading>Beneficios de invertir en propiedades</Heading>
            </Center>
            <UnorderedList spacing={3}>
                <ListItem>
                    La ubicación de nuestros desarrollos hacen que podamos apuntar a un público uruguayo muy potencial y siempre activo, además, existe una alta demanda para alquilar propiedades, así que no debes preocuparte por que tu propiedad no se arriende.
                </ListItem>
                <ListItem>

                    Una vez pagado el hipotecario o si fue comprado sin, tendrás un ingreso mensual todos los meses aparte de una gran rentabilidad cuando decidas venderla.
                </ListItem>
                <ListItem>
                    En primer lugar, inviertes en un bien tangible, tu propiedad, que gana valor gracias a la plusvalía con el paso del tiempo. En Argentina, el sector inmobiliario es estable.
                </ListItem>
                <ListItem>
                    Comparada a otros instrumentos financieros más tradicionales (depósito a plazo y fondos mutuos), la renta inmobiliaria es de mayor rentabilidad y de menor riesgo.
                </ListItem>
            </UnorderedList>
        </Flex>
    )
}

export default RealEstateBenefits