import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
type GuardedRouteProps = {
    isAllowed:boolean,
    redirectPath:string,
    children?:any
}
const GuardedRoute = ({
                            isAllowed,
                            redirectPath = '/login',
                            children,
                        }:GuardedRouteProps) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};
export default  GuardedRoute;