import React from 'react';
import {Center, Flex, Heading} from "@chakra-ui/react";
import CategoryCard from "./CategoryCard";

const ExploreOurProjects = () => {
    return (
        <Flex justify="center" direction="column"  mx={50}>
            <Center mb={5}>
                <Heading>Explorá nuestros proyectos</Heading>
            </Center>
            <CategoryCard image="/images/CondominiosChicago.jpeg"
                          title="Condominios Chicago"
                          roi="12-24%"
                          description="Condominios Chicagos será el desarrollo inmobiliario más atractivo moderno y cómodo de la ciudad de Concordia  para los jóvenes que quieren independizarse o simplemente venir a estudiar alguna de las carreras que ofrece la ciudad. Será un ecosistema excelente para el bienestar de las personas que vivan en el mismo ya que contará con amenidades como piscina propia de 10x3, lavandería, gimnasio, SUM, Quincho y 33 departamentos de entre 38mt2 y 42mt2 con balcón y vista al norte. También contará con 4 locales comerciales de excelente diseño."
                          reverse={false}
                          link="/real-estate/2"/>
            <CategoryCard  image="/images/barrio_privado.jpeg"
                           title="Barrios Privados"
                           roi="4-8%"
                           description="Consiste en la Construcción de viviendas para uso personal en un excelente barrio privado sustentable de la ciudad, con una metodología de venta agresiva, estamos seguros que serán una excelente oportunidad para un público focalizado en el proyecto como su primer vivienda. "
                           reverse={true}
                           link="/real-estate/3"/>
            <CategoryCard  image="/images/polo_comercial.jpeg"
                           title="Polo Comercial"
                           roi="N/A"
                           description="Polo comercial para diversos emprendimientos. Punto estratégico para la zona de estancia grande calabacilla puerto yerua y Nueva Escocia. Contará con Indumentaria, electrónica, gastronomía, diversión."
                           reverse={false}
                           link="/real-estate/mall/1"/>
        </Flex>
    );
};

export default ExploreOurProjects;