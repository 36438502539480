import theme from "../chakraTheme";
import {Button, Box, Flex, Heading, Image, Link, Text} from "@chakra-ui/react";
import { FaArrowRight} from "react-icons/all";

const VehicleBanner = () => {
    return (
        <Flex align="center" justify="space-around" bgColor={theme.colors.secondary} px={50} py={30}>
    <Flex direction="column" gap={10}>
    <Flex direction={{base:"column", sm:"row"}}>
    <Heading as="span" mr="7px" color={theme.colors.primary} >Compra/venta de </Heading>
        <Heading color={theme.colors.accent}  as="span"> vehículos de lujo </Heading>
        </Flex>
        <Text color={theme.colors.primary} w={{ base: "100%", sm:"75%"}}>Como jamás antes habías pensado, hoy podes ver nuestra flota de vehículos de alta gama que tenemos disponible para colateralizar nuestros proyectos o para comercializar, està atento a este sector porque frecuentemente aparecen excelentes oportunidades de compra de vehiculos que consideramos que están por debajo de su valor y las ganancias suelen ser muy buenas en el corto plazo! </Text>
    <Link href="/marketplace">
        <Button bgColor={theme.colors.whiteButtons} rightIcon={<FaArrowRight color={theme.colors.accent} />} w={{base:"70%", sm:"20%"}}>
            <Text bgColor={theme.colors.accent} bgClip="text">Ir al Marketplace</Text>
        </Button>
    </Link>

    </Flex>
            <Box display={{ base:'none', sm:'block'}}>
                <Image src="/images/cars_banner.png" w="700px"/>
            </Box>
        </Flex>
    );
};

export default VehicleBanner;