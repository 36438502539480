import axios from "axios";
import { paths } from "../macros/paths";

export class InvestmentOpService {

    public async properties() {
        const response = await fetch(paths.BASE_URL + '/api/properties/', {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json()).then((data) => {
            //TO-DO callback from balances
        })//TODO: manejo de errores
        .catch((error) => {console.log("Error fetching properties")})
        return response
    }

    public async cars() {
        return await axios.get(paths.BASE_URL + '/cars');
    }
    public async all() {
        const response = await fetch(paths.BASE_URL + '/api/all/', {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json()).then((data) => {
            //TO-DO callback from balances
        })//TODO: manejo de errores
            .catch((error) => {console.log("Error fetching all products")})
        return response
    }

}

