import { paths } from "../macros/paths";
import {stringify} from "querystring";
import axios from "axios";
// import { User } from "../model/User";

export class AuthService {

    public async login(email:String, password:String) {
        const response = await fetch(paths.BASE_URL + '/login', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email":email,"password":password})
        }).then((resp) => resp.json()).then((data) => {
            window.localStorage.setItem('authUser', data.accessToken)
            window.localStorage.setItem('userEmail', email);
            window.location.href = "/";
        })
        .catch(
            (error) => {
                throw error
            })
        return response
    }

    public async register(email:String, password:String, username:String) {
        axios.post(paths.BASE_URL + '/register', {"email":email,"password":password,"username":username})
            .then((resp) => {
                console.log("datini")
                window.localStorage.setItem('authUser', resp.data.accessToken)
                window.location.href = "/";
        })
        .catch((error) => console.log(error))
    }
    public async forgotPassword(email:String) {
        await fetch(paths.BASE_URL + '/forgotPassword', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email":email})
        }).then((resp) => resp.json()).then((data) =>{


        })
            //TODO: manejo de errores
            .catch((error) => console.log('failed to send email to user'))
    }
    public async changePassword(email:String) {
        await fetch(paths.BASE_URL + '/changePassword', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email":email})
        }).then((resp) => resp.json()).then((data) =>{


        })
            //TODO: manejo de errores
            .catch((error) => console.log('failed to change password'))
    }

    public async isAdmin() {
        return await axios.post(paths.BASE_URL + '/profile/isAdmin', {
            email: window.localStorage.getItem("userEmail")
        }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }});
    }

    
}

