import React, {useEffect} from 'react';
import {
    Button,
    ButtonGroup, Center,
    Flex, FormControl, FormErrorMessage,
    FormLabel,
    Heading, Link, NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper, useToast
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {authService, requestsService} from "../service/staticInstances";
import { useSearchParams} from "react-router-dom";

const Withdraw = () => {
    const toast = useToast();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        formState: { errors, isSubmitting },
    } = useForm()


    return (
        <Center my={16}>
            <Flex direction="column" justify="center" gap={16}>
                <Heading size="lg" textAlign="center">Estás por retirar dinero</Heading>
                <Heading size="md">Recordá que todas las transacciones suceden en la red TRC-20</Heading>
                <FormControl isInvalid={errors.name !== undefined}>
                    <FormErrorMessage>
                        {errors.name !== null && errors.name?.message !== null}
                    </FormErrorMessage>
                    <Center>
                        <ButtonGroup  margin="auto" >
                            <Link href="mailto:info@bancastokker.com">
                                <Button colorScheme="blackAlpha">Necesito ayuda</Button>
                            </Link>
                            <Button colorScheme="red"
                                    type="submit"
                                    isLoading={isSubmitting}
                                    onClick={async () =>{
                                        toast({
                                            title: 'Espera la acreditación de tus fondos',
                                            description: "Estamos confirmando tu retiro. Cuando el retiro esté aprobado, lo verás reflejado en tu wallet",
                                            status: 'success',
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                        await requestsService.requestWithdraw(Number(searchParams.get('amount')), searchParams.get('product'))
                                            .then(async (response)=> {
                                                    await new Promise(r => setTimeout(r, 2000));
                                                    window.location.href ='/profile';
                                            }
                                            )
                                            .catch((error)=>console.log(error))
                                    }

                                    }>Confirmar</Button>
                        </ButtonGroup>
                    </Center>

                </FormControl>
            </Flex>
        </Center>

    );
};

export default Withdraw;