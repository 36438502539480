import React from 'react';
import QuestionAndAnswer from "../components/QuestionAndAnswer";
import {Flex, Text} from "@chakra-ui/react";
import theme from "../chakraTheme";

const TermsAndConditions = () => {
    return (
        <Flex direction="column">
            <Flex justify="center" bgColor={theme.colors.secondary} padding={20}>
                <Text textColor={"white"} fontSize="3xl" fontWeight="bold">Terminos &nbsp; y </Text>
                <Text textColor={theme.colors.accent} fontSize="3xl" fontWeight="bold">&nbsp; Condiciones</Text>
            </Flex>
            <QuestionAndAnswer  question="" answer="Las operaciones no garantizadas, en cuyo caso EL CLIENTE asume el riesgo ante algún incumplimiento."/>
            <QuestionAndAnswer  question="" answer="BANCASTOKKER no asegura rendimientos de ningún tipo o cuantía, los % son aproximados en base a los calculos economicos de cada proyecto. Los riesgos de las Operaciones incluyen enunciativamente la posibilidad de efectos adversos por cambios macroeconómicos (por ejemplo, tasa de interés, nivel general de precios o inflación, devaluación o apreciación del valor de una moneda, recesión), de circunstancias del mercado (por ejemplo, falta de liquidez) o particulares a los activos adquiridos (por ejemplo, disminución o pérdida de solvencia del emisor). También las decisiones de autoridades públicas (nacionales o extranjeras) o de terceros por los cuales la Sociedad no responde ni puede controlar, son susceptibles de afectar adversamente los resultados de las operaciones, aun cuando no constituyan supuestos que califiquen legalmente como fuerza mayor."/>
            <QuestionAndAnswer  question="" answer="Especialmente, EL CLIENTE declara comprender y aceptar las circunstancias que implican realizar operaciones con entidades y/o terceros dentro de la República Argentina, y a la reglamentación a la que se está sujeto, aceptando especialmente la eventual posibilidad de que las operaciones puedan o no ser realizadas y/o liquidadas en virtud de la normativa vigente. Como consecuencia de lo indicado, EL CLIENTE entiende y acepta que ni EL AGENTE, ni sus accionistas, directores o sociedades vinculadas garantizan en modo alguno un rendimiento" />
            <QuestionAndAnswer question="" answer="EL CLIENTE manifiesta tener conocimiento de la naturaleza de las operaciones que realiza y de los riesgos inherentes a cada una de las mismas. Asimismo, declara y asume que las inversiones a ser realizadas están sujetas a múltiples variaciones en sus precios o cotizaciones incluyendo condiciones de mercado"/>
        </Flex>

    );
};

export default TermsAndConditions;