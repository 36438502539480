import React from 'react';
import RedAndBlackTitle from "./RedAndBlackTitle";
import {Box, Wrap, WrapItem} from "@chakra-ui/react";
import RealEstateCard from "./RealEstateCard";

const RealEstateOpportunities = () => {
    return (
       <Box mx="auto" marginLeft={5}>
           <RedAndBlackTitle redTitle="Invertí en" blackTitle="Desarrollos inmobiliarios y comerciales"/>
           <Wrap spacing={5} justify="center" w={["95vw", "100%", "100%", "90vw"]} direction={["column", "column", "column" ,"row"]}>
               <WrapItem>
                   <RealEstateCard blur={true}
                                   title="Condominios chicago"
                                   image="/images/bp3.jfif"
                                   location="Concordia, Entre Ríos"
                                   subtitle1="Plazo de inversión: 12 meses"
                                   subtitle2="33 departamentos + 4 locales"
                                   subtitle3="Rentabilidad: 12 al 24% en USD"
                                   funded={15}
                                   link="/real-estate/2"/>
               </WrapItem>
               <WrapItem>
                   <RealEstateCard blur={false}
                                   title="Condominios chicago"
                                   image="/images/CondominiosChicago.jpeg"
                                   location="Concordia, Entre Ríos"
                                   subtitle1="Plazo de inversión: 12 meses"
                                   subtitle2="33 departamentos + 4 locales"
                                   subtitle3="Rentabilidad: 12 al 24% en USD"
                                   funded={15}
                                   link="/real-estate/2"/>
               </WrapItem>
               <WrapItem>
                   <RealEstateCard blur={false}
                                   title="Polo comercial"
                                   image="/images/polo_comercial.jpeg"
                                   location="Calabacilla, Entre Ríos"
                                   subtitle1=""
                                   subtitle2="4 lotes 535.50 m^2"
                                   subtitle3="Rentabilidad: Consultar"
                                   funded={0}
                                   link="/real-estate/mall/1"/>
               </WrapItem>
           </Wrap>
       </Box>
    );
};

export default RealEstateOpportunities;