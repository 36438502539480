import React from 'react';
import {Box, Flex,} from "@chakra-ui/react";
import VehicleBanner from '../components/VehicleBanner';
import VehicleOpportunities from "../components/VehicleOpportunities";

const VehicleLanding = () => {
    return (
        <Flex direction="column" justify="center" gap={10}>
            <VehicleBanner/>
            <VehicleOpportunities/>
        </Flex>

    );
};

export default VehicleLanding;