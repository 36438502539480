import { Flex } from '@chakra-ui/react'
import RegisterCard from '../components/RegisterCard'

export default function Register() {
    return (
        <Flex direction="column" pb="10em">
            <RegisterCard/>
        </Flex>
    )    
}
