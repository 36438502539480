import React from 'react';
import RedAndBlackTitle from "./RedAndBlackTitle";
import {Avatar, Flex, Text, Wrap, WrapItem} from "@chakra-ui/react";

const AboutBancaStokker = () => {
    return (
       <Flex mx="50px"  direction="column">
           <RedAndBlackTitle redTitle="Quienes somos?" blackTitle="Sobre Banca Stokker"/>
           <Wrap justify="center">
               {/*<WrapItem>*/}
               {/*    <Flex direction="column" justify="center" align="center">*/}
               {/*        <Avatar name='Adriano Baldesari' src='/images/adriano.png' size="2xl" />*/}
               {/*        <Text as="i" textAlign="center"> Adriano, fundador</Text>*/}
               {/*    </Flex>*/}
               {/*</WrapItem>*/}
               <WrapItem>
                   <Text px={10}>BancaStokker se creó con una misión clara: Hacer trabajar tus ahorros y Democratizar las inversiones tradicionales, previamente restringida a individuos de altos ingresos, conocimientos muy específicos e inversores institucionales con millones para invertir. BancaStokkker brinda a sus clientes un acceso fácil y directo a inversiones respaldadas, con atractivos rendimientos potenciales.
                       Tradicionalmente las inversiones se hacían por medio de un broker, o las personas caían en inversiones bancarias que históricamente siempre fueron muy riesgosas (Corralito, corridas cambiarias, etc) a raíz de esto y para que todos tengan una herramienta de inversión nosotros decidimos crear BancaStokker donde las personas pueden asociarse  a nuestros negocios actuales para que sean partícipes de los beneficios generados por los mismos.
                   </Text>
               </WrapItem>
           </Wrap>
       </Flex>
    );
};

export default AboutBancaStokker;