import * as React from "react"
import {  BrowserRouter as Router,
    Routes,
    Route} from 'react-router-dom'

import Navbar from './components/Navbar';

import {
  ChakraProvider,
} from "@chakra-ui/react"

import theme from './chakraTheme'
import Home from "./views/Home";
import Footer from "./components/Footer";
import TermsAndConditions from "./views/TermsConditions";
import RealEstateLanding from "./views/RealEstateLanding";
import RealEstateProject from "./views/RealEstateProject";
import Login from "./views/Login";
import Register from "./views/Register";
import FAQs from "./views/FAQs";
import Deposit from "./views/Deposit";
import InvestingProfile from "./views/InvestingProfile";
import Marketplace from "./views/Marketplace";
import Withdraw from "./views/Withdraw";
import VehicleLanding from "./views/VehicleLanding";
import MiningLanding from "./views/MiningLanding";
import SimulatorView from "./views/SimulatorView";
import GuardedRoute from "./utils/GuardedRoute";
import Manage from "./views/Manage";
import { NavbarType } from "./components/NavBarType";
import RealEstateProjectMall from "./views/mall";

function hasJWT() : boolean {
    return window.localStorage.getItem("authUser") !== null;
}
export const App = () => (
  <ChakraProvider theme={theme}>
      <Router>
          { hasJWT() ?  <NavbarType/> : <Navbar/>}
          <Routes>
              <Route element={<GuardedRoute  redirectPath={"/login"} isAllowed={hasJWT()}/>}>
                  <Route path="/deposit" element={<Deposit/>}/>
                  <Route path="/profile" element={<InvestingProfile/>}/>
                  <Route path="/withdraw" element={<Withdraw/>}/>
                  <Route path="/manage" element={<Manage/>}/>
              </Route>

              <Route path="/real-estate" element={<RealEstateLanding/>}/>
              <Route path="/real-estate/:id" element={<RealEstateProject/>}/>
              <Route path="/real-estate/mall/:id" element={<RealEstateProjectMall/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/register" element={<Register/>}/>
              <Route path="/faqs" element={<FAQs/>}/>
              <Route path="/simulator" element={<SimulatorView/>}/>
              <Route path="/marketplace" element={<Marketplace/>}/>
              <Route path="/vehicles" element={<VehicleLanding/>}/>
              <Route path="/mining" element={<MiningLanding/>}/>
              <Route path="/" element={<Home/>}/>
              <Route path="/terms" element={<TermsAndConditions/>}/>
          </Routes>
          <Footer/>
      </Router>
  </ChakraProvider>
)
export default App;
