import { useState, useEffect } from "react";
import { authService } from "../service/staticInstances";
import AdminNavBar from "./AdminNavbar";
import LoggedNavbar from "./LoggedNavbar";



export function NavbarType () {
    const [admin, setAdmin] = useState(1);

    useEffect(() => {
        authService.isAdmin().then((response) => {
        setAdmin(response.data)
    })}, []);

    return admin ? <LoggedNavbar/> : <AdminNavBar/>;
}

