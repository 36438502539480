import React from 'react';
import {Flex, Box, Heading, Center} from "@chakra-ui/react";
import MiningBanner from '../components/MiningBanner';
import CategoryCard from "../components/CategoryCard";

const MiningLanding = () => {
    return (
        <Flex direction="column" justify="center" gap={10}>
            <MiningBanner/>
            <Center>
                <Heading>¿En qué puedo invertir?</Heading>
            </Center>
            <Center>
                <CategoryCard image="images/rig2.png"
                              title={"Fuerza de minado (Temporalmente suspendido)"}
                              roi={"12-24%"}
                              description="El porcentaje que se maneja es el más tentador de nuestro mercado ya que es un interés aproximado de la minería del 30% Anual en usd (Restando gastos de luz, mantenimiento, técnicos, equipos de refrigeración, etc) la renta también depende del precio de ETH que es la moneda que minamos actualmente y la dificultad de minado que afecta o beneficia directamente a la rentabilidad
                            Cabe destacar que esta inversión está disponible siempre y cuando tengas mhz libres para comprar que sobren de los que nosotros producimos."
                              reverse={false}
                              link={"mailto:info@bancastokker.com"}/>
            </Center>
            <Box/>
        </Flex>

    );
};

export default MiningLanding;