import {Button, ButtonGroup, Flex, Heading, Image, Text, Link, Box} from "@chakra-ui/react";
import theme from "../chakraTheme";

const HomeBanner = () => {
    return (
        <Flex direction="column">
            <Flex align="center" justify="space-around" bgColor={theme.colors.secondary} p={{ base:10}} >
                <Flex direction="column">
                    <Flex direction="row">
                        <Heading as="span" mr="7px" bgColor={theme.colors.primary} bgClip="text">Donde </Heading>
                        <Heading bgColor={theme.colors.accent} bgClip="text" as="span"> tus ahorros </Heading>
                    </Flex>
                    <Heading bgColor={theme.colors.primary} bgClip="text">rinden más</Heading>
                    <ButtonGroup mt='2em'>
                        <Link href="/deposit">
                        <Button bgColor={theme.colors.whiteButtons}>
                            <Text textColor={theme.colors.accent} _hover={{ textDecoration:"none"}}>Depositá</Text>
                        </Button>
                        </Link>
                        <Link href="mailto:info@bancastokker.com">
                            <Button variant="outline" textColor="white" >Contacto</Button>
                        </Link>
                    </ButtonGroup>
                </Flex>
                <Box display={{ base: 'none', sm:'block' }} >
                    <Image src="/images/home.png" />
                </Box>

            </Flex>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1a202c" fillOpacity="1" d="M0,32L80,53.3C160,75,320,117,480,122.7C640,128,800,96,960,117.3C1120,139,1280,213,1360,250.7L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>

        </Flex>
    );
}

export default HomeBanner;