import { paths } from "../macros/paths";
import axios from "axios";

export class AdminServise {
    public async requestBalance(email: string) {        
        return await axios.post(paths.BASE_URL + '/admin/balancesfrom',
            {
                email: window.localStorage.getItem("userEmail"),
                investor_email: email,
            }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        });
    }

    public async changeStatus(email: string, product: string, price: number) {
        return await axios.post(paths.BASE_URL + '/admin/add', {
            email: window.localStorage.getItem("userEmail"),
            investor_email: email,
            productname: product,
            price: price
        }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        });
    }

    public async confirmWithdraw(email: string, price: number, product: string) {
        return await axios.post(paths.BASE_URL + '/admin/withdraw', {
            email: window.localStorage.getItem("userEmail"),
            investor_email: email,
            price: price,
            productname: product
        }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        });
    }

    public async deletePub(category: string, product: string) {
        const cat = category === "Vivienda" ? 0 : 1;
        return await axios.post(paths.BASE_URL + '/admin/withdraw', {
            email: window.localStorage.getItem("userEmail"),
            category: cat,
            productname: product
        }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        });
    }

    public async deleteTransaction(email: string, product: string, date: string, baseAmount: Number, accAmount: Number) {    
        return await axios.post(paths.BASE_URL + '/admin/balances/delete', {
            email: window.localStorage.getItem("userEmail"),
            investor_email: email,
            product_name: product,
            creation_date: date,
            base_amount: baseAmount,
            accumulated_interest: accAmount,
        }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        });
    }

    public async addTransaction(email: string, product: string, bAmount: Number, aAmount: Number) {                
        return await axios.post(paths.BASE_URL + '/admin/balances/add', {
            email: window.localStorage.getItem("userEmail"),
            investor_email: email,
            product_name: product,
            base_amount: bAmount,
            accumulated_interest: aAmount
        }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        });
    }

    public async getUsers() {
        return await axios.post(paths.BASE_URL + '/admin/getUsers', {
            email: window.localStorage.getItem("userEmail"),
        }, {
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("authUser")}`
            }
        });
    }

}