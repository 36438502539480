import React from 'react';
import { Box, Button, Center, Flex, Heading, Image, Link, Text } from "@chakra-ui/react";

import theme from "../chakraTheme";
type VehicleCardProps = {
    image: string,
    title: string,
    description: string,
    price: number
}

const VehicleCard = ({ image, title, description, price }: VehicleCardProps) => {
    return (
        <Box maxW={["100%"]} borderWidth='1px' borderRadius='lg' overflow='hidden' boxShadow="xl" w={["250px", "300px", "300px", "400px"]} >
            <Image src={image} boxSize='100%'></Image>

            <Flex px={6} py={3} justify="center">
                <Heading size="md">{title}</Heading>
            </Flex>
            <Flex direction="column" justify="center" align="center" p={6}>
                <Text bgColor="#676767" bgClip="text">{description}</Text>
            </Flex>
            <Center>
                <Text fontWeight="bold">Precio: {price} USD</Text>
            </Center>
            <Center my="2em">
                <Button colorScheme="red" >
                    <Link href="https://bit.ly/VehiculosBancaStokker">
                        <Text bgColor={theme.colors.primary} bgClip="text">Quiero más info</Text>
                    </Link>
                </Button>
            </Center>
        </Box>
    );
};

export default VehicleCard;