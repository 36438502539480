import {
    Box,
    Flex,
    Text,
    IconButton,
    Image,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from "@chakra-ui/icons";
import theme from "../chakraTheme";
import { useState, useEffect } from "react";
import { authService } from '../service/staticInstances';

function logout() {
    window.localStorage.removeItem("authUser");
    window.location.reload();
}

export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure();
    const [admin, setAdmin] = useState(1);

    useEffect(() => {
        authService.isAdmin().then((response) => {
        setAdmin(response.data)
    })}, []);

    return (
        <Box>
            <Flex
                bg={useColorModeValue(theme.colors.secondary, theme.colors.secondary)}
                color={useColorModeValue('white', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}>
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
                    <Text
                        textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                        fontFamily={'heading'}
                        color={useColorModeValue('white', 'white')}>
                        <Image src="/images/BancaStokker.jfif" w="191px" />
                    </Text>

                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    <Button
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'outline'}
                        _hover={{
                            textColor: theme.colors.accent,
                        }}
                        onClick={logout}>
                        Cerrar Sesión
                    </Button>

                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('white', 'white');
    const linkHoverColor = useColorModeValue('white', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'white');

    return (
        <Stack direction={'row'} spacing={4} my="auto">
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                p={2}
                                href={navItem.href ?? '#'}
                                fontSize={'sm'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: theme.colors.accent,
                                }}>
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue(theme.colors.accent, theme.colors.accent) }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: theme.colors.accent }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={theme.colors.accent} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue(theme.colors.secondary, theme.colors.secondary)}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('white', 'white')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Inicio',
        href: '/'
        // children: [
        //     {
        //         label: 'Explore Design Work',
        //         subLabel: 'Trending Design to inspire you',
        //         href: '#',
        //     },
        //     {
        //         label: 'New & Noteworthy',
        //         subLabel: 'Up-and-coming Designers',
        //         href: '#',
        //     },
        // ],

    },
    {
        label: 'Marketplace de inversiones',
        href: '/marketplace'
        // children: [
        //     {
        //         label: 'Job Board',
        //         subLabel: 'Find your dream design job',
        //         href: '#',
        //     },
        //     {
        //         label: 'Freelance Projects',
        //         subLabel: 'An exclusive list for contract work',
        //         href: '#',
        //     },
        // ],
    },
    {
        label: 'Mi perfil',
        href: '/profile',
    },
    {
        label: 'Invertí con nosotros',
        href: '/simulator',
    },
    {
        label: 'FAQs',
        href: '/faqs',
    },
];

const adminElement : NavItem = {
    label: 'Gestion',
        href: '/manage',
}
// import {  Link as RouteLink } from "react-router-dom";
// import { HStack, Link, Spacer, Text, Image} from "@chakra-ui/react";
// import theme from "../chakraTheme";
//
// type NavLinkProps = { text: string };
// const NavLink = ({ text }: NavLinkProps) => (
//     <Link>
//         <Text fontSize="md" fontWeight="bold"  bgColor={theme.colors.primary} bgClip="text" textDecorationColor="red.500">{text}</Text>
//     </Link>
// );
//
// const LoggedNavbar = () => {
//     return (
//         <HStack as='nav' spacing={7} px={10} py={5} backgroundColor={theme.colors.secondary} >
//             <RouteLink to="/">
//                 <Image src="/images/BancaStokker.jfif" w="191px"/>
//             </RouteLink>
//             <Spacer/>
//             <RouteLink to="/">
//                 <Text textColor="white" fontWeight="bold">Inicio</Text>
//             </RouteLink>
//             <RouteLink to="/marketplace">
//                 <Text textColor="white" fontWeight="bold">Marketplace de inversiones</Text>
//             </RouteLink>
//             <RouteLink to="/profile/1">
//                 <Text textColor="white" fontWeight="bold">Mi perfil</Text>
//             </RouteLink>
//             <RouteLink to="/faqs">
//                 <Text textColor="white" fontWeight="bold">FAQs</Text>
//             </RouteLink>
//         </HStack>
//     );
// }
//
// export default LoggedNavbar;