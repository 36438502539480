import theme from "../chakraTheme";
import {Button,Box, Flex, Heading, Image, Link, Text} from "@chakra-ui/react";
import { FaArrowRight} from "react-icons/all";

const RealEstateBanner = () => {
    return (
        <Flex align="center" justify="space-around" bgColor={theme.colors.secondary} px={50} py={30}>
    <Flex direction="column" gap={10}>
    <Flex direction="row" justify="left">
    <Heading as="span" mr="7px" color={theme.colors.primary} >Desarrollo </Heading>
        <Heading color={theme.colors.accent}  as="span"> Inmobiliario </Heading>
        </Flex>
        <Text color={theme.colors.primary} w="100%">Inversiones con garantía inmobiliaria al alcance de todos Participa en proyectos inmobiliarios de alta rentabilidad y respaldo tangible, completamente online. Invertí en ladrillo y protegé tus ahorros.</Text>
    <Link href="/marketplace">
        <Button bgColor={theme.colors.whiteButtons} rightIcon={<FaArrowRight color={theme.colors.accent} />} w="200px">
            <Text bgColor={theme.colors.accent} bgClip="text">Ir al Marketplace</Text>
        </Button>
    </Link>

    </Flex>
    <Box display={{ base:'none', sm:'block'}}>
                <Image src="/images/dollar.png"/>
    </Box>

        </Flex>
    );
};

export default RealEstateBanner;