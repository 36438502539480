import React from 'react';
import {Flex, Heading, Text} from "@chakra-ui/react";

type QuestionAndAnswerProps = {
    question:string,
    answer:string
}
const QuestionAndAnswer = ({question,answer}:QuestionAndAnswerProps) => {
    return (
        <Flex direction="column" my={10} mx="50px">
            <Heading>{question}</Heading>
            <Text>{answer}</Text>
        </Flex>

    );
};

export default QuestionAndAnswer;