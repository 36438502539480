import React from 'react';
import RedAndBlackTitle from "./RedAndBlackTitle";
import {Box, Wrap, WrapItem} from "@chakra-ui/react";
import VehicleCard from "./VehicleCard";
import MotorCicleCard from './MotorcicleCard';

const VehicleOpportunities = () => {
    return (
       <Box mx="auto">
           <RedAndBlackTitle redTitle="Invertí en" blackTitle="Vehículos"/>
           <Wrap spacing={4} justify="center" align={"center"} w={["100%", "100%", "100%", "90vw"]} direction={["column", "column", "row" ,"row"]}>
               <WrapItem>
                   <VehicleCard image="/images/x6.jpeg"
                                title="BMW X6 2013 3.0 xDrive 306cv"
                                description="Service recién hecho. Listo para vender y con muchos agregados."
                                price={55000}/>
               </WrapItem>
               <WrapItem>
                   <VehicleCard image="/images/bmw_ale.jpeg"
                                title="BMW 335I 2013 F30"
                                description="Service recién hecho. Listo para vender con muchos agregados."
                                price={45000}/>
               </WrapItem>
               <WrapItem>
                   <VehicleCard image="/images/bmw_328.jpeg"
                                title="BMW 328I Luxury"
                                description="120000km Service recién hecho. Tratamiento cerámico."
                                price={28500}/>
               </WrapItem>
               <WrapItem>
                   <VehicleCard image="/images/bmw_135.jpeg"
                                title="BMW 135I 2010"
                                description="54000km Medio equipo de escape, cubiertas nuevas."
                                price={30000}/>
               </WrapItem>
               <WrapItem>
                   <MotorCicleCard image="/images/moto.jpeg"
                                title="BMW S1000rr 2014"
                                description="Muy armada"
                                price={29000}
                   />
               </WrapItem>
               <WrapItem>
                   <MotorCicleCard image="/images/CForce2022.jpeg"
                                title="CForce 2022"
                                description="1000km lista para transferir."
                                price={8500}/>
               </WrapItem>
               <WrapItem>
                   <MotorCicleCard image="/images/seadoo.jpeg"
                                title="Seadoo 2060xrt RS"
                                description="Impecable con trailer homolagado."
                                price={22000}/>
               </WrapItem>
               <WrapItem>
                   <VehicleCard image="/images/mercedesC.jpeg"
                                title="Mercedes Benz c250 2010 pack AMG"
                                description="Excelente estado, bateria nueva."
                                price={21000}/>
               </WrapItem>
               <WrapItem>
                   <VehicleCard image="/images/amarok2020.jpeg"
                                title="Amarok v6 2020"
                                description="100000km primera mano."
                                price={45000}/>
               </WrapItem>
               <WrapItem>
                   <MotorCicleCard image="/images/amarok2018.jpeg"
                                title="Amarok v6 2018"
                                description="86000km batería nueva, service recien hecho."
                                price={37000}/>
               </WrapItem>
               <WrapItem>
                   <VehicleCard image="/images/vwtiguan.jpeg"
                                title="VW Tiguan 2013"
                                description="93000km suspension escape pájaro stage 3, service recien hecho."
                                price={19000}/>
               </WrapItem>
               <WrapItem>
                   <VehicleCard image="/images/audi_a1.jpeg"
                                title="Audi A1 2016"
                                description="110000km único dueño. Tratamiento cerámico carpo."
                                price={22500}/>
               </WrapItem>
           </Wrap>
       </Box>
    );
};

export default VehicleOpportunities;